.suomi-hand-font {
  font-family: 'Licorice', cursive;
}

.suomi-hand-font {
  font-family: 'SuomiHand', normal;
}

@font-face {
  font-family: 'SuomiHand';
  src: local('SuomiHand'),
    url('../fonts/Suomi/Suomi-Hand.otf') format('opentype');
  font-weight: normal;
}
